import React, {FC} from "react";
import Code from "../../components/code";
import Layout from "../../components/layout/layout-sidebar";
import Seo from "../../components/seo";
import {sidebarItemsGettingStarted} from "../../data/sidebar-items-getting-started";

const SelectorsPage: FC = () => (
    <Layout sidebarItems={sidebarItemsGettingStarted}>
        <Seo
            title="Getting started with grid selectors"
            description="Instructions for selecting cells, neighbouring cells, columns, rows or areas in a grid."
        />
        <section className="p-8">
            <h1>{`Selectors`}</h1>
            <p>
                {`To select data from a grid instance, gridl provides some selector function, which allow you to access data like cell values, columns, rows, subgrids or neighbouring cells.`}
            </p>

            <a id="selectCell" className="text-current hover:text-current">
                <h2>{`Selecting a single cell`}</h2>
            </a>
            <p>{`Select a cell at a certain x,y position in the grid.`}</p>
            <Code code={codeSelectCell} language="javascript" />

            <h2>{`Selecting a column`}</h2>
            <p>{`Select a column at a certain x-position in the grid.`}</p>
            <Code code={codeSelectColumn} language="javascript" />

            <h2>{`Selecting a row`}</h2>
            <p>{`Select a row at a certain y-position in the grid.`}</p>
            <Code code={codeSelectRow} language="javascript" />

            <h2>{`Selecting a sub grid`}</h2>
            <p
                dangerouslySetInnerHTML={{
                    __html: `The sub grid selector allows you to extract a certain area of a grid as a new grid instance. The area is specified by <code>x</code>, <code>y</code>, <code>columnCount</code> and <code>rowCount</code> values.`,
                }}
            />
            <Code code={codeSelectSubgrid} language="javascript" />

            <h2>{`Selecting neighbouring cells`}</h2>
            <p>
                {`Select some or all neighbouring cells of a certain cell in the grid. By default, the selector returns all surrounding neighbours. This can be changed by providing a list of positions with the optional `}
                <code>positions</code>
                {` property.`}
            </p>
            <Code code={codeSelectNeighbours} language="javascript" />
        </section>
    </Layout>
);

export default SelectorsPage;

const codeSelectCell = `import {createGridFromArray2D, selectCell} from "gridl/core";

const grid = createGridFromArray2D([
    [0,  1,  2,  3],
    [4,  5,  6,  7],
    [8,  9, 10, 11],
]);

// get the cell value at position = {x: 1, y: 2}
selectCell({grid, x: 2, y: 1}); // => 6`;

const codeSelectColumn = `import {createGridFromArray2D, selectColumn} from "gridl/core";

const grid = createGridFromArray2D([
    [  0,   1,   2,   3],
    [  4,   5,   6,   7],
    ["x", "x", "x", "x"],
]);

// get the column at x = 2
selectColumn({grid, x: 2}); // => [2, 6, "x"]`;

const codeSelectRow = `import {createGridFromArray2D, selectRow} from "gridl/core";

const grid = createGridFromArray2D([
    [0,  1,  2,  3],
    [4,  5,  6,  7],
    [8,  9, 10, 11],
]);

// get the row at y = 1
selectRow({grid, y: 1}); // => [4, 5, 6, 7];`;

const codeSelectSubgrid = `import {createGridFromArray2D, selectSubGrid} from "gridl/core";

const grid = createGridFromArray2D([
    [  0,   1,   2,   3],
    [  4,   5,   6,   7],
    ["x", "x", "x", "x"],
]);

selectSubGrid({grid, x: 1, y: 1, columnCount: 2, rowCount: 2});
// returned subgrid (which is a new grid instance):
// {
//     x: 1,
//     y: 1,
//     cellCount: 4,
//     columnCount: 2,
//     rowCount: 2,
//     array2D: [
//         [  5,   6],
//         ["x", "x"],
//     ],
// }`;

const codeSelectNeighbours = `import {
    createGridFromArray2D,
    selectSubGrid,
    NorthWest,
    North,
    NorthEast,
    OrthogonalDirections,
} from "gridl/core";

const grid = createGridFromArray2D([
    [0,  1,  2,  3],
    [4,  5,  6,  7],
    [8,  9, 10, 11],
]);

// get all neighbouring cells around the origin at position {x: 1, y: 0}
selectNeighbours({grid, origin: {x: 1, y: 0}});
// => [
//     {cell:  1,  position: {x: 1, y: 0}},
//     {cell:  2,  position: {x: 2, y: 0}},
//     {cell:  6,  position: {x: 2, y: 1}},
//     {cell: 10,  position: {x: 2, y: 2}},
//     {cell:  9,  position: {x: 1, y: 2}},
//     {cell:  8,  position: {x: 0, y: 2}},
//     {cell:  4,  position: {x: 0, y: 1}},
//     {cell:  0,  position: {x: 0, y: 0}},
// ]

// get orthogonal neighbours only (North, East, South, East)
selectNeighbours({grid, origin: {x: 1, y: 1, positions: OrthogonalDirections}});
// => [
//     {cell: 1,  position: {x: 1, y: 0}},
//     {cell: 6,  position: {x: 2, y: 1}},
//     {cell: 9,  position: {x: 1, y: 2}},
//     {cell: 4,  position: {x: 0, y: 1}},
// ]

// select top neighbours only
const topNeighbourPositions = [NorthWest, North, NorthEast];
selectNeighbours({grid, origin: {x: 2, y:1}, positions: topNeighbourPositions});
// => [
//     {cell: 1,  position: {x: 1, y: 0}},
//     {cell: 2,  position: {x: 2, y: 0}},
//     {cell: 3,  position: {x: 3, y: 0}},
// ]`;
